/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBookshelfAssessment = `subscription OnCreateBookshelfAssessment($file: String!) {
  onCreateBookshelfAssessment(file: $file) {
    file
    result
    ttl
    error
  }
}
`;
